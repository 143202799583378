import React from 'react';
import HamburgerMenu from './HamburgerMenu';
import smoothScroll from '../smoothScroll';


function Header() {

	document.addEventListener('DOMContentLoaded', function() {
		const icon = document.getElementById('nav-icon4');
	
		if (icon) {
			icon.addEventListener('click', function() {
				this.classList.toggle('open');
			});
		}
	});

	return (
		<div className='bg-primary sticky top-0 width-full z-20'>
			<nav className='flex justify-between'>
				<h4 className='text-xl lg:text-2xl p-5 pl-10 xxs:pl-5 items-center'><a onClick={() => smoothScroll('top')} className='cursor-pointer hover:text-bg'>Angelyce Phipps</a></h4>
				<div className='xl:hidden 2xl:hidden'>
					<HamburgerMenu position='top'/>
				</div>
				<ul className='hidden header-nav xl:flex 2xl:flex text-xl font-heading text-text items-center gap-10 pr-10'>
					<li><button onClick={() => smoothScroll('home')}>Home</button></li>
          <li><button onClick={() => smoothScroll('about')}>About</button></li>
          <li><button onClick={() => smoothScroll('services')}>Services</button></li>
          <li><button onClick={() => smoothScroll('footer')}>Contact</button></li>
				</ul>
			</nav>
		</div>
	)
}

export default Header;