import './HomePage.css';
import React from 'react';
import sparkle from './assets/images/SparkleMobile.svg';
import smoothScroll from './smoothScroll';

function HomePage() {

  return (
    <div id='home' className='bg-bg h-screen w-full mobile-home'>
      <header className='flex flex-col gap-4 text-center mx-auto w-fit md:text-left lg:text-left'>
        <img src={sparkle} className='h-20 w-20 mx-auto mt-1/6 
                                    xs:mt-5 md:hidden lg:hidden'/>
        <h2 className='lg:text-4xl md:mt-10 lg:mt-20 '>
          <span className='color-effect'>Everyone</span> <br/>deserves therapy.
        </h2>
        <div className='mx-2/12 flex flex-col gap-3 my-6 xs:my-0 xs:mx-4 md:mx-0 lg:mx-0'>
          <p>
          Angelyce Phipps is passionate about affordable and accessible therapy.     
          </p>
          <p>
          She believes that therapy should be a conversation with meaning, purpose, and style.
          </p>
        </div>
        <ul className='flex gap-4 mt-4 justify-center text-md md:justify-start lg:justify-start'>
          <button className='navy-button'><a onClick={() => smoothScroll('aboutmob')}>About</a></button>
          <button className='teal-button'><a onClick={() => smoothScroll('contactmob')}>Contact</a></button>
        </ul>
      </header>
    </div>
  );
}
//bg-bg/50 rounded-[10px] lg:p-4
export default HomePage;
