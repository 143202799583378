import React, { forwardRef, useState, useEffect } from 'react';
import sparkle from '../assets/images/Sparkle.svg';
import HamburgerMenu from './HamburgerMenu';
import smoothScroll from '../smoothScroll';


const Footer = forwardRef((props, ref) => {

  document.addEventListener('DOMContentLoaded', function() {
		const icon = document.getElementById('nav-icon4');
	
		if (icon) {
			icon.addEventListener('click', function() {
				this.classList.toggle('open');
			});
		}
	});

  return (
    <div ref={ref} id='footer' className='bg-text w-full text-bg z-50 relative lg:flex lg:justify-between xl:flex xl:justify-between 2xl:flex 2xl:justify-between'>
      <div>
        <nav className='flex'>
          <div className='p-5 pl-10 xxs:pl-5 items-center w-full'>
            <h3><a href="/" onClick={smoothScroll}>Angelyce Phipps</a></h3>

            <div className='flex flex-row align-middle pt-1 text-xs'>
              <p>Website by <span><a href='https://www.linkedin.com/in/timidgeek/' className='hover-effect' target='_blank'>Lindsey Thomas</a></span></p>
              <img src={sparkle} alt='sparkle icon' className='h-4 px-2' />
              <button><a href='https://timidgeek.com/' className='hover-effect' target='_blank'>timidgeek</a></button>
            </div>
          </div>
        </nav>

        <div className='lg:hidden xl:hidden 2xl:hidden'>
          <HamburgerMenu position='bottom' />
        </div>
      </div>

      <ul className='hidden header-nav lg:flex xl:flex 2xl:flex text-xl font-heading text-bg items-center gap-10 pr-10'>
        <li><button onClick={() => smoothScroll('home')}>Home</button></li>
        <li><button onClick={() => smoothScroll('about')}>About</button></li>
        <li><button onClick={() => smoothScroll('services')}>Services</button></li>
        <li><button onClick={() => smoothScroll('footer')}>Contact</button></li>
      </ul>


    </div>
  )
});

Footer.displayName = 'Footer';

export default Footer