import React from 'react';
import sunburst from '../assets/images/Sunburst.svg';
import sparkle from '../assets/images/SparkleMobile.svg';

const AboutDesktop = () => {
  return (
    <div id='about' className='bg-text text-bg h-screen w-full flex flex-row 3xl:px-[14rem]'>

<section className='flex-1 flex-col gap-3 ml-[5rem] mr-10 flex  justify-center h-auto'>
  <h1>About</h1>
  <p>
  Angelyce Phipps, a dedicated mental health professional based in Nebraska, offers a
  100% remote Telehealth experience. She is committed to making therapy affordable and accessible to all.
  </p>
  <h4 className='mt-5'>Educational Background and Experience</h4>
  <p>
  Angelyce graduated from the University of Oklahoma with a Bachelor of Science in Psychology. After 
  raising a family and enjoying a successful 20-year career as a Massage Therapist, she earned a 
  Master of Science in Community Counseling from Oklahoma State University. Angelyce employs an eclectic 
  counseling approach, drawing from various disciplines with a strong emphasis on Cognitive Behavioral Therapy. 
  She specializes in counseling adults, couples, and adolescents. Angelyce has 15 years of experience as a mental 
  health therapist.
  </p>
  <h4 className='mt-5'>Counseling Philosophy and Goals</h4>
  <p>
  For Angelyce, the goal of counseling is to provide steadfast support to her clients. She aims to help clients 
  gain insight into their thoughts, beliefs, verbalizations, actions, and interactions, enabling them to set new goals 
  and explore different ways of living. Beyond talk therapy, Angelyce emphasizes the importance of identifying 
  community resources to aid clients in their journey towards success. Her passion lies in helping people find hope, 
  purpose, and meaning in their lives.
  </p>
</section>


<section className='flex items-center justify-center h-full relative mt-6 mr-20'>
  <img src={sunburst} className='flex justify-items items-center' alt="sunburst"/>
  <div className='h-full w-full absolute top-0 left-0 text-center flex flex-col justify-center items-center'>
    <h1 className='text-accent text-center'>
      Angelyce <br/> Phipps
    </h1>
    <h3 className='text-secondary'>L.P.C., L.I.M.P.H.</h3>
  </div>
</section>


</div> 
  )
}

export default AboutDesktop