import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import logo from '../assets/images/JoyMenu.png'

import smoothScroll from '../smoothScroll';

const HamburgerMenu = ({ position }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const toggleMenu = () => {
    if (isAnimating) return;
    setIsAnimating(true);
    setIsOpen(!isOpen);
    setTimeout(() => setIsAnimating(false), 300);
  };

  return (
    <div>
      {/* {isOpen && (
      <header className='fixed top-1 left-6 p-4 z-50 xxs:p-2'> 
        <h4 className='text-xl items-center'>Angelyce Phipps</h4>
      </header>
      )} */}
      <div className='relative'>
        <button
          onClick={toggleMenu}
          className={`absolute ${position === 'top' ? 'top-2' : 'bottom-6'} right-7 z-50 text-3xl focus:outline-none`}
        >
          <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
        </button>
      </div>

      {isOpen && (
        <div className="fixed inset-0 bg-primary text-center z-40 flex justify-center">
          <nav className="text-white text-2xl my-auto">
            <img src={logo} alt='menu logo' className='h-[18rem] w-[18rem]'/>
            <ul className="mt-6 space-y-6">
              <li><a href="/" onClick={toggleMenu} className='navy-button'>Home</a></li>
              <li><a href="#aboutmob" onClick={toggleMenu} className='navy-button'>About</a></li>
              <li><a href="#servicesmob" onClick={toggleMenu} className='navy-button'>Services</a></li>
              <li><a href="#contactmob" onClick={toggleMenu} className='navy-button'>Contact</a></li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;