import React from 'react'
import sunburst from '../assets/images/Sunburst.svg';
import sparkle from '../assets/images/SparkleMobile.svg';

const About = () => {
  return (
    <div id='aboutmob' className='bg-text text-bg h-auto w-full flex flex-col'>

      <section className='flex-1 flex-col gap-5 mx-6 pt-10 flex justify-center h-auto md:mx-20 md:mt-10 lg:mx-20 lg:mt-10'>

        <div className='text-center mx-2'>
          <h2 className='lg:text-4xl'>About</h2>
          <p>
          Angelyce Phipps, a dedicated mental health professional based in Nebraska, offers a
          100% remote Telehealth experience. She is committed to making therapy affordable and accessible to all.
          </p>
        </div>

        <img src={sparkle} alt='sparkle icon' className='h-20 w-20 mx-auto' />

        <div>
          <h4 className='mb-3'>Educational Background and Experience</h4>
          <p>
          Angelyce graduated from the University of Oklahoma with a Bachelor of Science in Psychology. After 
          raising a family and enjoying a successful 20-year career as a Massage Therapist, she earned a 
          Master of Science in Community Counseling from Oklahoma State University. Angelyce employs an eclectic 
          counseling approach, drawing from various disciplines with a strong emphasis on Cognitive Behavioral Therapy. 
          She specializes in counseling adults, couples, and adolescents. Angelyce has 15 years of experience as a mental 
          health therapist.
          </p>
        </div>

        <img src={sparkle} alt='sparkle icon' className='h-20 w-20 mx-auto' />

        <div className='text-right'>
          <h4 className='mb-3'>Counseling Philosophy and Goals</h4>
          <p>
          For Angelyce, the goal of counseling is to provide steadfast support to her clients. She aims to help clients 
          gain insight into their thoughts, beliefs, verbalizations, actions, and interactions, enabling them to set new goals 
          and explore different ways of living. Beyond talk therapy, Angelyce emphasizes the importance of identifying 
          community resources to aid clients in their journey towards success. Her passion lies in helping people find hope, 
          purpose, and meaning in their lives.
          </p>
        </div>

      </section>


      <section className='flex items-center justify-center h-full relative my-8 mx-4 md:my-20 lg:my-20'>
        <img src={sunburst} className='flex justify-items items-center' alt="sunburst"/>
        <div className='h-full w-full absolute top-0 left-0 text-center flex flex-col justify-center items-center'>
          <h2 className='text-accent text-center xxs:text-2xl'>
            Angelyce <br/> Phipps
          </h2>
          <h3 className='text-secondary xxs:text-xl'>L.P.C., L.I.M.P.H.</h3>
        </div>
      </section>


    </div>
  )
}

export default About
