import React from 'react'
import sparkle from '../assets/images/SparkleAccent.svg';

const ServicesDesktop = () => {
  return (
    <div id='services' className='bg-primary flex flex-col 3xl:px-[14rem]'>
        <section className='flex items-center flex-col'>
          <div className='bg-secondary text-bg w-screen p-5 h-64 flex flex-col justify-center items-center'>
            <h1 className='text-center'>Services</h1>
            <p className='text-xl text-center'>Angelyce offers a range of Telehealth services to support your mental health and wellbeing.</p>
          </div>
        </section>

        <section className='flex flex-row mt-14 mb-20'>

          <div className='flex-col flex-1 justify-around ml-[7rem] flex justify-center bg-bg p-10 mr-[3rem] rounded-[50px]'>     
            <div className='flex flex-col gap-1'>
              <h4>Individual Therapy</h4>
              <p>Personalized sessions to address your unique needs and goals.</p>
            </div>

            {/* <hr className='border-secondary border-[2px] my-4 mx-40'></hr> */}

            <div className='flex flex-col gap-1'>
              <h4>Family Therapy</h4>
              <p>Collaborative sessions to address family dynamics and conflicts.</p>
            </div>

            {/* <hr className='border-secondary border-[2px] my-4 mx-40'></hr> */}

            <div className='flex flex-col gap-1'>
              <h4>Couples Counseling</h4>
              <p>
              Improve communication and strengthen your relationship.
              A particular area of interest for Angelyce is addressing couples’ relationship 
              difficulties, such as communication issues, commitment, sexual relations, financial 
              problems, and recovery from infidelity in marriage or long-term relationships.
              </p>
            </div>

            {/* <hr className='border-secondary border-[2px] my-4 mx-40'></hr> */}

            <div className='flex flex-col gap-1'>
              <h4>Adolescent Therapy</h4>
              <p>Professional support for teens to manage their emotional and psychological well-being.</p>
            </div>
          </div>

          <section className='mr-[7rem] flex flex-1 flex-col gap-[3rem]'> 
            <div className='flex-col gap-5 bg-bg p-10 rounded-[50px]'>
              <div className=''>
                <h4>Areas of Focus</h4>
                <ul className='ml-6 mt-4 flex flex-col gap-3'>
                  <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Depression and anxiety</li>
                  <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Post-traumatic stress disorder (PTSD)</li>
                  <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Sexual issues</li>
                  <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Anger and stress management</li> 
                  <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Personal growth</li>
                </ul>
              </div>
            </div>

            <div className='flex flex-col gap-5 bg-bg p-10 rounded-[50px]'>
              <h4>Pricing & Insurance</h4>
              <p>
              Angelyce accepts Medicaid, most insurances, and private pay.<br/>

              For information on session pricing, please reach out to 
              Angelyce directly via the contact form below.
              </p>
            </div>
          </section>

      </section>
    </div>
  )
}

export default ServicesDesktop