import React from 'react';
import sparkle from '../assets/images/SparkleAccent.svg';

function Services() {
  return (
    <div id='servicesmob' className='bg-primary text-text h-fit w-full flex flex-col services-mobile sm:bg-cover md:bg-cover lg:bg-cover'>
      <section className='flex items-center flex-col'>
        <div className='bg-accent text-bg rounded-[50px] p-5 text-center mx-4 mt-8 xxs:mx-5 sm:mx-10 md:mx-20 lg:mx-0 lg:p-6'>
          <h2 className='xxs:text-2xl lg:text-4xl'>Services</h2>
          <p className='lg:text-xl'>Angelyce offers a range of Telehealth services to support your mental health and wellbeing.</p>
        </div>
      </section>

      <section className='flex-col gap-5 p-10 xxs:p-5 lg:p-5 xxs:my-7'>
        <div className='w-fit items-center mx-auto'>
          <h4 className='text-center'>Areas of Focus</h4>
          <ul className='ml-2 mt-4 flex flex-col gap-3'>
            <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Depression and anxiety</li>
            <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Post-traumatic stress disorder (PTSD)</li>
            <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Sexual issues</li>
            <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Anger and stress management</li> 
            <li className='flex flex-row items-center'><img src={sparkle} className='h-5 pr-2 fill-accent' alt='sparkle' />Personal growth</li>
          </ul>
        </div>
      </section>

      <section className='flex-col flex justify-center py-10 pt-0 mx-14 xxs:mx-5 md:mx-20 lg:mx-40'>

      <hr className='border-secondary border-[2px] mx-10 my-5'></hr>

        <div className='flex flex-col gap-1'>
            <h4>Individual Therapy</h4>
            <p>Personalized sessions to address your unique needs and goals.</p>
        </div>

        <hr className='border-secondary border-[2px] mx-10 my-5'></hr>

        <div className='flex flex-col gap-1 text-right'>
          <h4>Family Therapy</h4>
          <p>Collaborative sessions to address family dynamics and conflicts.</p>
        </div>

        <hr className='border-secondary border-[2px] mx-10 my-5'></hr>

        <div className='flex flex-col gap-1'>
          <h4>Couples Counseling</h4>
          <p>
          Improve communication and strengthen your relationship.
          A particular area of interest for Angelyce is addressing couples’ relationship 
          difficulties, such as communication issues, commitment, sexual relations, financial 
          problems, and recovery from infidelity in marriage or long-term relationships.
          </p>
        </div>

        <hr className='border-secondary border-[2px] mx-10 my-5'></hr>

        <div className='flex flex-col gap-1 text-right '>
          <h4>Adolescent Therapy</h4>
          <p>Professional support for teens to manage their emotional and psychological well-being.</p>
        </div>
      </section>

      <section className='flex items-center flex-col sm:mx-10 md:mx-20'>
        <div className='bg-accent text-bg rounded-[50px] p-5 text-center mx-4 mt-4 xxs:p-2 lg:p-1'>
          <h2 className='text-2xl xxs:pt-4 lg:text-3xl'>Pricing & Insurance</h2>
          <p className='lg:text-xl m-4'>Angelyce accepts Medicaid, most insurances, and private pay.<br/>
            For information on session pricing, please reach out to 
            Angelyce directly via the contact form below.</p>
        </div>
      </section>

  </div>
  )
}

export default Services      