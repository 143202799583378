import React from 'react'
import smoothScroll from '../smoothScroll'

const HomePageDesktop = () => {
  return (
    <div id='home' className='bg-bg h-screen w-full px-20 home-container xl:px-[10rem] 2xl:px-[20rem] 3xl:px-[20rem]'>
      <header className='flex flex-col gap-4 w-fit'>
        <h1 className='mt-1/3'>
        <span className='color-effect'>Everyone</span> deserves therapy.
        </h1>
        <p>
        Angelyce Phipps is passionate about affordable and accessible therapy.<br/>
        She believes that therapy should be a conversation with meaning, purpose, and style.
        </p>
        <ul className='flex gap-4 ml-10 mt-4'>
          <button className='orange-button'><a onClick={() => smoothScroll('about')}>About</a></button>
          <button className='teal-button'><a onClick={() => smoothScroll('contact')}>Contact</a></button>
        </ul>
      </header>
    </div>
  )
}

export default HomePageDesktop