import React, { useState } from 'react';
import Footer from './Footer';
import contact from '../assets/images/Contact.svg'
import InputMask from 'react-input-mask';
import sparkle from '../assets/images/SparkleContact.svg'

const Contact = () => {

    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      message: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({ ...prev, [name]: value }));
      // Clear the error for this field
      setFormErrors(prev => ({ ...prev, [name]: '' }));
    };
  
    const validateForm = () => {
      const errors = {};
      if (!formData.name) errors.name = 'Name is required';
      if (!formData.email) errors.email = 'Email is required';
      else if (!formData.email.includes('@')) errors.email = 'Email must contain "@"';
      if (!formData.phone) errors.phone = 'Phone is required';
      if (!formData.message) errors.message = 'Message is required';
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (validateForm()) {
        setIsSubmitting(true);
        try {
          const response = await fetch("https://formspree.io/f/mzzpdbon", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
          if (response.ok) {
            alert('Thank you for your submission, Angelyce will get back to you as soon as possible!');
            setFormData({ name: '', email: '', phone: '', message: '' });
          } else {
            alert('There was an error submitting the form. Please try again.');
          }
        } catch (error) {
          console.error('Error:', error);
          alert('There was an error submitting the form. Please try again.');
        }
        setIsSubmitting(false);
      }
    };

  return (
    <div id='contactmob' className='bg-accent relative flex flex-col justify-center'>

{/* <div className='mt-6 mb-4 mx-5 py-3 lg:mb-0 lg:py-0 sm:mb-0 sm:mt-1/3 md:mx-20 md:mt-1/4 lg:mx-20 lg:mt-1/6 bg-text rounded-[50px]'> */}
      <div className='m-5 max-w-96 lg:max-w-[30rem] xl:max-w-[35rem] xl:m-10 lg:my-10 2xl:mt-20 bg-text rounded-[50px]
                      sm:mx-auto md:mx-auto lg:mx-auto xl:mx-auto 2xl:mx-auto'>
        <img className='mx-auto my-2' src={sparkle} style={{height:60, width:60}} alt="sparkle"></img>
        <h2 className='text-center sm:mt-10 text-bg'>Contact</h2>
        <p className='mx-5 pb-4 text-center text-bg'>
          Angelyce is here to listen and support you. Fill out the form below, 
          and she will get in touch to schedule an appointment.
        </p>
      </div>


      <form 
        // action="https://formspree.io/f/mzzpdbon"
        // method="POST"
        className="mx-5 mb-5 space-y-5 mobile-form bg-bg p-3 rounded-[50px] md:mx-10 md:mb-10 lg:mb-10 xl:mb-10 2xl:mb-20 lg:w-[700px] xl:w-[700px] 2xl:w-[700px] lg:mx-auto xl:mx-auto 2xl:mx-auto lg:p-6 xl:p-6 2xl:p-6 3xl:p-6"
        onSubmit={handleSubmit}>
        
        <div>
          <label htmlFor="name" className="block text-lg text-text font-heading mb-1 mt-4 ml-2">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full px-4 py-2 rounded-full bg-primary bg-opacity-20 border-2 border-accent focus:outline-none focus:border-indigo-300"
            required
          />
          {formErrors.name && <span className='text-red-500'>{formErrors.name}</span>}
        </div>
        
        <div>
          <label htmlFor="email" className="block text-lg text-text font-heading mb-1 ml-2">Email</label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            name="email"
            className="w-full px-4 py-2 rounded-full bg-primary bg-opacity-20 border-2 border-accent focus:outline-none focus:border-indigo-300"
            required
          />
          {formErrors.email && <span className='text-red-500'>{formErrors.email}</span>}
        </div>
        
        <div>
          <label htmlFor="phone" className="block text-lg text-text font-heading mb-1 ml-2">Phone</label>
          <InputMask
            mask="(999) 999-9999"
            maskChar=" "
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            className="w-full px-4 py-2 rounded-full bg-primary bg-opacity-20 border-2 border-accent focus:outline-none focus:border-indigo-300"
            required
          />
          {formErrors.phone && <span className='text-red-500'>{formErrors.phone}</span>}
        </div>
        
        <div>
          <label htmlFor="message" className="block text-lg text-text font-heading mb-1 ml-2">Message</label>
          <textarea
            id="message"
            name="message"
            rows="4"
            value={formData.message}
            onChange={handleInputChange}
            className="w-full px-4 py-2 rounded-3xl bg-primary bg-opacity-20 border-2 border-accent focus:outline-none focus:border-indigo-300"
            required
          ></textarea>
          {formErrors.message && <span className='text-red-500'>{formErrors.message}</span>}
        </div>

        <div className='flex justify-center'>
        <button type='submit' className='mt-4 orange-button mb-2' disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </button>
        </div>

      </form>

    </div>
  )
}

export default Contact